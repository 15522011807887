import React from 'react'
import styled from 'styled-components'

const Row = styled.tr``
const Col = styled.td``

export default function InputGroup({ label, id, children, ...props }) {
  return (
    <Row {...props}>
      <Col>{children.map(child=><label key={child.props.id} htmlFor={child.props.id}>{child.props.label}</label>)}</Col>
      <Col>{children}</Col>
    </Row>
  )
}
